const React = require('react');
const Layout = require('./src/hoc/layout/Layout').default;
require('./src/styles/Colours.css');
require('./src/styles/typography.css');
require('./src/styles/global.css');

exports.wrapPageElement = ({ element, props }) => {
	return <Layout {...props}>{element}</Layout>;
};

const TRANSITION_DELAY = 800;

exports.shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
	if (location.action === 'PUSH') {
		window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY);
	} else {
		const savedPosition = getSavedScrollPosition(location);
		window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), TRANSITION_DELAY);
	}
	return false;
};
