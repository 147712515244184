import React, { useMemo } from 'react';
import SubscribeForm from '../subscribeForm/SubscribeForm';
import Container from '../../hoc/container/Container.js';
import facebookIcon from '../../assets/social/facebook.svg';
import instagramIcon from '../../assets/social/instagram.svg';
import phoneIcon from '../../assets/icons/phone.svg';
import logo from '../../assets/brand/logo.svg';
import style from './Footer.module.css';

const Footer: React.FC = () => {
	const year = useMemo(() => new Date().getFullYear(), []);
	return (
		<footer>
			<Container large>
				<div className={style.subscribe}>
					<div className={style.logo}>
						<img src={logo} alt="SY Icon" />
					</div>
					<div className={style.form}>
						<SubscribeForm />
					</div>
				</div>

				<p>
					Superior Yachting specialises in providing charters on both Sail and Motor yachts in the Mediterranean. With
					our industry expertise we can match you to your dream charter vessel, be it Sail or Motor, Classic or Modern.
				</p>

				<div className={style.socialRow}>
					<a
						title="Connect with Superior Yachting on Facebook"
						target="_blank"
						href="https://www.facebook.com/superioryachting"
						rel="noopener noreferrer"
					>
						<img src={facebookIcon} alt="Facebook Icon" />
					</a>

					<a
						title="Follow Superior Yachting on Instagram"
						target="_blank"
						href="https://www.instagram.com/superior.yachting"
						rel="noopener noreferrer"
					>
						<img src={instagramIcon} alt="Instagram Icon" />
					</a>

					<a title="Call the Superior Yachting Head Office" href="tel:44 20 3633 6723">
						<img src={phoneIcon} alt="Telephone" />
					</a>
				</div>
			</Container>
			<div className={style.legalBar}>
				<p>
					© Copyright {year} | All rights reserved | Superior Yachting Ltd | Website by{' '}
					<a href="https://loopspeed.co.uk" title="Go to Loopspeed website" rel="noreferrer" target="_blank">
						Loopspeed
					</a>
				</p>
			</div>
		</footer>
	);
};

export default Footer;
