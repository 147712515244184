import React from 'react';
// import PropTypes from 'prop-types'
import NavButton from '../navButton/NavButton';
import style from './TabNav.module.css';

const TabNav = () => (
	<nav className={style.container}>
		<NavButton id="home" title="Home" to="/" isMobile={true} />
		<NavButton id="browse" title="Browse" to="/browse" isMobile={true} />
		<NavButton id="contact" title="Enquire" to="/contact" isMobile={true} />
	</nav>
);

TabNav.propTypes = {};
TabNav.defaultProps = {};

export default TabNav;
