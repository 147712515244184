import React, { useState, useEffect, useLayoutEffect, useCallback } from 'react';
import { Transition, SwitchTransition } from 'react-transition-group';
import gsap from 'gsap';
import { PageProps } from 'gatsby';
import imagesloaded from 'imagesloaded';

import { lockScroll, unlockScroll } from '../../functions/functions';

import SiteLoading from '../../components/loading/SiteLoading';
import Navbar from '../../components/navigation/navBar/NavBar';
import TabNav from '../../components/navigation/tabNav/TabNav';
import Menu from '../../components/navigation/menu/Menu';
import Footer from '../../components/footer/Footer';

import WindowContext from '../../contexts/WindowContext';
import LoadingContext from '../../contexts/LoadingContext';
import ReferralContext from '../../contexts/ReferralContext';
import useQuery from '../../hooks/useQuery';

const Layout: React.FC<PageProps> = ({ children, location }) => {
	const [showMenu, setShowMenu] = useState(false);
	const [hasLoaded, setHasLoaded] = useState(false);
	const [windowDimensions, setWindowDimensions] = useState(() => {
		// For SSR Gatsby build
		let initialState = { width: 750, height: 1334 };
		if (typeof window !== 'undefined') {
			initialState = { width: window.innerWidth, height: window.innerHeight };
		}
		return initialState;
	});

	const [referrerId, setRefererrerId] = useState<string | null>(null);

	const isMobile = windowDimensions.width < 768;

	useEffect(() => {
		lockScroll();
		console.time('load');
		imagesloaded('#main', { background: true }, onLoad);
		window.addEventListener('resize', handleResize);
		storeReferrerId();

		return () => {
			window.removeEventListener('load', onLoad);
			window.removeEventListener('resize', handleResize);
			unlockScroll();
		};
	}, []);

	const storeReferrerId = useCallback(() => {
		const searchParams = new URLSearchParams(location.search);
		const ref = searchParams.get('ref');
		if (ref) {
			console.log('storing ref:', ref);
			setRefererrerId(ref);
		}
	}, []);

	const onLoad = useCallback(() => {
		setHasLoaded(true);
		unlockScroll();
		console.timeEnd('load');
	}, []);

	// Updates the window dimensions when resizing has finished
	const handleResize = useCallback(() => {
		const windowSize = {
			width: window.innerWidth,
			height: window.innerHeight,
		};
		setTimeout(() => {
			if (windowSize.width === window.innerWidth && windowSize.height === window.innerHeight) {
				setWindowDimensions({
					width: window.innerWidth,
					height: window.innerHeight,
				});
			}
		}, 200);
	}, []);

	const menuToggleHandler = useCallback(() => {
		setShowMenu((prev) => !prev);
	}, []);

	return (
		<LoadingContext.Provider value={hasLoaded}>
			<WindowContext.Provider value={windowDimensions}>
				<ReferralContext.Provider value={referrerId}>
					<SiteLoading hasLoaded={hasLoaded} />
					<Navbar isMobile={isMobile} menuTogglePressed={menuToggleHandler} menuIsOpen={showMenu} />
					<div style={{ minHeight: '100vh' }}>
						<SwitchTransition mode="out-in">
							<Transition
								key={location.pathname}
								mountOnEnter
								unmountOnExit
								timeout={1000}
								onExiting={(node: HTMLElement) => gsap.to(node, { opacity: 0, duration: 0.5 })}
								onEnter={(node: HTMLElement) => gsap.set(node, { opacity: 0 })}
								onEntering={(node: HTMLElement) => gsap.to(node, { opacity: 1, duration: 1 })}
							>
								<main id="main">{children}</main>
							</Transition>
						</SwitchTransition>
					</div>
					<Footer />
					<Menu show={showMenu} toggleMenu={menuToggleHandler} />
					{isMobile && <TabNav />}
				</ReferralContext.Provider>
			</WindowContext.Provider>
		</LoadingContext.Provider>
	);
};

export default Layout;
