import React from 'react';
import styles from './SiteLoading.module.scss';
// import logo from '../../assets/brand/logo.svg';

type SiteLoadingProps = {
	hasLoaded: boolean;
};

const SiteLoading: React.FC<SiteLoadingProps> = ({ hasLoaded }) => {
	return (
		<div
			className={styles.container}
			style={{
				display: hasLoaded ? 'none' : 'flex',
			}}
		>
			<div className={styles.spinner}>
				<div />
				<div />
				<div />
			</div>
		</div>
	);
};

export default SiteLoading;
