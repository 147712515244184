// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-browse-js": () => import("./../../../src/pages/browse.js" /* webpackChunkName: "component---src-pages-browse-js" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-yacht-destinations-js": () => import("./../../../src/pages/yacht-destinations.js" /* webpackChunkName: "component---src-pages-yacht-destinations-js" */),
  "component---src-pages-yacht-jobs-js": () => import("./../../../src/pages/yacht-jobs.js" /* webpackChunkName: "component---src-pages-yacht-jobs-js" */),
  "component---src-templates-yacht-js": () => import("./../../../src/templates/yacht.js" /* webpackChunkName: "component---src-templates-yacht-js" */)
}

