import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'gatsby';

import MenuToggle from '../menuToggle/MenuToggle';
import Container from '../../../hoc/container/Container';
import NavButton from '../navButton/NavButton';

import logo from '../../../assets/brand/logo.svg';
import logoMini from '../../../assets/brand/logoMini.svg';
import style from './NavBar.module.css';

const NavBar = ({ isMobile, menuIsOpen, menuTogglePressed }) => {
	return (
		<nav className={style.nav}>
			<Container large containerClass={style.wrapper}>
				<Link to="/">
					<img className={style.logo} src={isMobile ? logoMini : logo} alt="Superior Yachting Logo" />
				</Link>

				<div className={style.controls}>
					{/* These links aren't shown on narrow screens*/}
					{!isMobile && (
						<>
							<NavButton id="home" title="Home" to="/" isMobile={false} />
							<NavButton id="browse" title="Browse" to="/browse" isMobile={false} />
							<NavButton id="contact" title="Book a Consultation" to="/contact" isMobile={false} />
						</>
					)}
					<MenuToggle isOpen={menuIsOpen} clicked={menuTogglePressed} />
				</div>
			</Container>
		</nav>
	);
};

export default NavBar;

// NavBar.propTypes = {}
