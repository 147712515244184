import React from 'react';
import style from './MenuToggle.module.css';

const MenuToggle = ({ isOpen, clicked }) => (
	<div className={`${style.hamburger} ${isOpen ? style.collapseActive : style.collapse}`} onClick={clicked}>
		<div className={style.hamburgerBox}>
			<div className={style.hamburgerInner} />
		</div>
	</div>
);

export default MenuToggle;
