import React from 'react';
import { Link } from 'gatsby';
import style from './NavButton.module.scss';

const NAV_ICONS = {
	home: (
		<svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" alt="Home">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M85.8363 17.4385V35.5684L100 46.4645L93.098 55.4363L50 22.2734L6.90215 55.4361L0 46.4643L50 8L71.3508 24.4248V17.4385H85.8363ZM15.6044 56.142L50.0001 29.6754L84.396 56.142V91.6008H57.9251V65.6155H43.2425V91.6008H15.6044V56.142Z"
				fill="white"
			/>
		</svg>
	),
	browse: (
		<svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" alt="Browse">
			<g clipPath="url(#clip0)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M84.9206 61.7302C82.5396 39.5079 73.0158 8.55556 37.3015 3C37.3015 3 46.8253 18.0794 48.4126 61.7302C49.1552 61.6865 49.8906 61.6428 50.6194 61.5995H50.6194C63.1302 60.8564 73.6711 60.2302 84.9206 61.7302ZM32.5396 9.34917C32.5396 9.34917 15.873 60.1428 9.52378 69.6666C21.4285 64.9047 42.0635 62.5238 42.0635 62.5238C42.0635 62.5238 40.4762 19.6666 32.5396 9.34917ZM0.968255 87.1269C0.519238 85.5679 0.195456 83.9756 0 82.365C0 82.365 26.9841 69.6666 100 67.2856C99.6617 67.7085 99.2424 68.2214 98.7611 68.8101C94.7258 73.7458 86.3388 84.0043 84.9206 91.0952C73.712 86.9438 62.7205 89.3068 49.8779 92.0677C38.168 94.5851 24.9192 97.4334 8.56349 95.9761C7.68241 95.9879 6.82492 95.6913 6.1394 95.1377C5.45388 94.584 4.98345 93.8082 4.80952 92.9444C4.72125 92.4694 4.66191 91.9807 4.60331 91.4981C4.32999 89.2472 4.07252 87.1269 0.968255 87.1269Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0">
					<path d="M0 0H100V100H0V0Z" fill="white" />
				</clipPath>
			</defs>
		</svg>
	),
	contact: (
		<svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" alt="Contact">
			<g clipPath="url(#clip0)">
				<path
					d="M9.37447 99.9994C7.17665 99.9994 5.29414 98.4765 4.80695 96.3659L0.120046 76.0533C-0.127112 74.9823 0.00996706 73.8584 0.507328 72.8782C1.00469 71.898 1.8307 71.1238 2.841 70.6908L24.7155 61.316C25.6499 60.9156 26.6892 60.8298 27.6766 61.0715C28.6641 61.3133 29.5462 61.8695 30.1899 62.6563L39.8765 74.4955C55.1716 67.3195 67.4695 54.8522 74.4958 39.8765L62.6564 30.1899C61.8696 29.5461 61.3134 28.6641 61.0716 27.6766C60.8299 26.6892 60.9156 25.6499 61.316 24.7155L70.6911 2.841C71.1241 1.8307 71.8983 1.00469 72.8785 0.507331C73.8588 0.00996929 74.9826 -0.127112 76.0536 0.120046L96.3662 4.80695C97.3991 5.04507 98.3208 5.62629 98.9807 6.45574C99.6407 7.28519 100 8.31387 100 9.37385C100 59.4654 59.3919 99.9994 9.37447 99.9994Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0">
					<path d="M0 0H100V100H0V0Z" fill="white" />
				</clipPath>
			</defs>
		</svg>
	),
};

const NavButton = ({ id, title, to, isMobile }) => (
	<Link to={to} className={style.link} activeClassName={style.active}>
		<button
			className={id === 'contact' && !isMobile ? style.primaryButton : style.button}
			style={{ flexDirection: isMobile ? 'column' : 'row' }}
			role="link"
		>
			<div style={{ display: 'inherit', margin: isMobile ? '6px' : '0 8px 0 0' }}>{NAV_ICONS[id]}</div>
			{title}
		</button>
	</Link>
);

export default NavButton;
