import React, { useState, useRef } from 'react';
import style from './SubscribeForm.module.css';

const SubscribeForm = (props) => {
	const [email, setEmail] = useState('');
	const form = useRef(null);

	const handleSubmit = () => {
		form.current.submit();
	};

	return (
		<form
			ref={form}
			action="https://superioryachting.us19.list-manage.com/subscribe/post?u=e15b888e3f79faf6fadaffc7e&amp;id=e73c49bf99"
			method="post"
			id="mc-embedded-subscribe-form"
			name="mc-embedded-subscribe-form"
			target="_blank"
			noValidate
			className={style.container}
		>
			<input
				type="email"
				value={email}
				name="EMAIL"
				id="mce-EMAIL"
				placeholder="Your email address"
				required
				onChange={(e) => setEmail(e.target.value)}
			/>
			{/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
			<div style={{ position: 'absolute', left: -5000 }} aria-hidden="true">
				<input type="text" name="b_e15b888e3f79faf6fadaffc7e_e73c49bf99" tabIndex="-1" value="" />
			</div>

			<button onClick={handleSubmit} title="Subscribe" className={style.button}>
				Subscribe
			</button>
		</form>
	);
};

export default SubscribeForm;
