import React from 'react'
import PropTypes from 'prop-types'
import style from './Container.module.css'

const Container = ({large, children, containerStyle, containerClass}) => (
  <div
    className={[large ? style.large : style.normal, containerClass].join(' ')}
    style={containerStyle}
  >
    {children}
  </div>
)

Container.propTypes = {
  large: PropTypes.bool,
  containerStyle: PropTypes.object,
  containerClass: PropTypes.string,
  children: PropTypes.node
}

Container.defaultProps = {
  large: false
}

export default Container
