import gsap from 'gsap';

export const buildThresholdList = (steps) => {
	var thresholds = [];
	var numSteps = steps;
	for (var i = 1.0; i <= numSteps; i++) {
		var ratio = i / numSteps;
		thresholds.push(ratio);
	}
	// thresholds.push(0)
	return thresholds;
};

export const lockScroll = () => {
	document.width = window.innerWidth;
	document.height = window.innerHeight;
	document.body.style.overflow = 'hidden';
	document.body.scroll = 'no';
};

export const unlockScroll = () => {
	document.body.style.overflow = 'scroll';
	document.body.scroll = 'yes';
};

export const returnRectDifferences = (rect1, rect2) => {
	const xDif = rect1.x - rect2.x;
	const yDif = rect1.y - rect2.y;
	const xScaleRatio = rect1.width / rect2.width;
	const yScaleRatio = rect1.height / rect2.height;
	return { x: xDif, y: yDif, scaleX: xScaleRatio, scaleY: yScaleRatio };
};

export const scrollToId = (id) => {
	const targetElement = document.getElementById(id);
	// const scrollOffset = id === Sections.about.id ? 0 : 180
	const offsetTop = targetElement && targetElement.offsetTop;

	// console.log('offsetTop', offsetTop);
	// If nested, need to get parent items offset
	// const offsetParent = targetElement && targetElement.offsetParent;
	// const offsetParentTop = offsetParent && offsetParent.offsetTop;
	// console.log('offsetParentTop', offsetParentTop);
	// const scrollTo = offsetParentTop > 0 ? offsetParentTop - scrollOffset : offsetTop - scrollOffset;

	const scrollTo = offsetTop + window.innerHeight;

	const duration = returnScrollDuration(offsetTop - window.scrollY);
	gsap.to(window, { duration: duration, scrollTo: scrollTo });
};

export const scrollToPosition = (position = 0) => {
	const duration = returnScrollDuration(window.scrollY - position);
	gsap.to(window, { duration: duration, scrollTo: position });
};

// Function adjusts duration based on distance so that it isn't really fast or really slow
const returnScrollDuration = (distancePx) => Math.abs(Number(distancePx / 900).toFixed(1));

// Returns long numbers like '100,000,000' for currency
export const formatNumber = (number) => new Intl.NumberFormat().format(number);
