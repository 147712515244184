import React, { useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'gatsby';
import style from './Menu.module.css';

import { lockScroll, unlockScroll } from '../../../functions/functions';
import Container from '../../../hoc/container/Container';

const CONTAINER_VARIANTS = {
	initial: {
		opacity: 0,
	},
	visible: {
		opacity: 1,
		transition: {
			when: 'beforeChildren',
		},
	},
	exit: {
		opacity: 0,
		transition: {
			when: 'afterChildren',
		},
	},
};

const OPTION_VARIANTS = {
	initial: (i) => ({
		opacity: 0,
		x: i % 2 ? 120 : -120,
	}),
	visible: { opacity: 1, x: 0 },
	exit: { opacity: 0 },
};

const MENU_OPTIONS = [
	{ label: 'Home', to: '' },
	{ label: 'Browse', to: 'browse' },
	{ label: 'FAQ', to: 'faq' },
	{ label: 'Jobs', to: 'yacht-jobs' },
	{ label: 'About', to: 'about' },
	{ label: 'Contact', to: 'contact' },
];

const Menu = ({ show, toggleMenu }) => {
	useEffect(() => {
		if (show) return lockScroll();
		return unlockScroll();
	}, [show]);

	return (
		<AnimatePresence>
			{show && (
				<motion.div variants={CONTAINER_VARIANTS} initial="initial" animate="visible" exit="exit">
					<Container containerClass={style.container}>
						<div className={style.close} onClick={toggleMenu} />
						<div className={style.grid} onClick={toggleMenu}>
							{MENU_OPTIONS.map((option, i) => (
								<motion.div ke={i} className={style.option} custom={i} variants={OPTION_VARIANTS}>
									<Link to={option.to}>
										<h2>{option.label}</h2>
									</Link>
								</motion.div>
							))}
						</div>
					</Container>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default Menu;
